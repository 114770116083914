var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"add-karyawan-sidebar","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.addKaryawanSidebar,"right":"","no-header":"","backdrop":""},on:{"hidden":function (val) { return _vm.changeValue(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.karyawanLocal.id > 0 ? 'Edit Karyawan' : 'Add Karyawan')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules",staticClass:"px-2"},[_c('b-form',[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama","label-for":"karyawan-name"}},[_c('validation-provider',{attrs:{"name":"Karyawan Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"karyawan-name","state":errors.length > 0 ? false:null,"placeholder":"Nama Karyawan"},model:{value:(_vm.karyawanLocal.nama),callback:function ($$v) {_vm.$set(_vm.karyawanLocal, "nama", $$v)},expression:"karyawanLocal.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"No Telepon","label-for":"karyawan-no-telepon"}},[_c('validation-provider',{attrs:{"name":"No Telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"karyawan-no-telepon","state":errors.length > 0 ? false:null,"placeholder":"No Telepon"},model:{value:(_vm.karyawanLocal.no_telp),callback:function ($$v) {_vm.$set(_vm.karyawanLocal, "no_telp", $$v)},expression:"karyawanLocal.no_telp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Posisi","label-for":"karyawan-posisi"}},[_c('validation-provider',{attrs:{"name":"Posisi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"karyawan-posisi","state":errors.length > 0 ? false:null,"placeholder":"Posisi"},model:{value:(_vm.karyawanLocal.posisi),callback:function ($$v) {_vm.$set(_vm.karyawanLocal, "posisi", $$v)},expression:"karyawanLocal.posisi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Gaji","label-for":"karyawan-gaji"}},[_c('validation-provider',{attrs:{"name":"Gaji","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"karyawan-gaji","type":"number","state":errors.length > 0 ? false:null,"placeholder":"Gaji"},model:{value:(_vm.karyawanLocal.gaji),callback:function ($$v) {_vm.$set(_vm.karyawanLocal, "gaji", $$v)},expression:"karyawanLocal.gaji"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.karyawanLocal.id > 0 && _vm.karyawanLocal.foto_karyawan)?_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"preview-image"}},[_vm._v("Preview Foto Sebelumnya: ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-img',{staticClass:"mb-2",attrs:{"id":"preview-image","width":"200","src":_vm.karyawanLocal.foto_karyawan,"fluid":"","alt":"Preview Image"}})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Foto Karyawan","label-for":"foto-karyawan"}},[_c('b-form-file',{attrs:{"id":"foto-karyawan","accept":"image/*","placeholder":"Pilih File"},model:{value:(_vm.karyawanLocal.foto_karyawan),callback:function ($$v) {_vm.$set(_vm.karyawanLocal, "foto_karyawan", $$v)},expression:"karyawanLocal.foto_karyawan"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeValue(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }