<template>
  <div>
    <b-sidebar
      id="add-karyawan-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="addKaryawanSidebar"
      right
      no-header
      backdrop
      @hidden="(val) => changeValue(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ karyawanLocal.id > 0 ? 'Edit Karyawan' : 'Add Karyawan' }}
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <validation-observer
          ref="simpleRules"
          class="px-2"
        >
          <b-form>
            <b-row class="px-2">
              <b-col md="12">
                <b-form-group
                  label="Nama"
                  label-for="karyawan-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Karyawan Name"
                    rules="required"
                  >
                    <b-form-input
                      id="karyawan-name"
                      v-model="karyawanLocal.nama"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nama Karyawan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="No Telepon"
                  label-for="karyawan-no-telepon"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="No Telepon"
                    rules="required"
                  >
                    <b-form-input
                      id="karyawan-no-telepon"
                      v-model="karyawanLocal.no_telp"
                      :state="errors.length > 0 ? false:null"
                      placeholder="No Telepon"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Posisi"
                  label-for="karyawan-posisi"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Posisi"
                    rules="required"
                  >
                    <b-form-input
                      id="karyawan-posisi"
                      v-model="karyawanLocal.posisi"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Posisi"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Gaji"
                  label-for="karyawan-gaji"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Gaji"
                    rules="required"
                  >
                    <b-form-input
                      id="karyawan-gaji"
                      v-model="karyawanLocal.gaji"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Gaji"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="karyawanLocal.id > 0 && karyawanLocal.foto_karyawan"
                md="12"
              >
                <b-row>
                  <b-col md="12">
                    <label for="preview-image">Preview Foto Sebelumnya: </label>
                  </b-col>
                  <b-col md="12">
                    <b-img
                      id="preview-image"
                      class="mb-2"
                      width="200"
                      :src="karyawanLocal.foto_karyawan"
                      fluid
                      alt="Preview Image"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Foto Karyawan"
                  label-for="foto-karyawan"
                >
                  <b-form-file
                    id="foto-karyawan"
                    v-model="karyawanLocal.foto_karyawan"
                    accept="image/*"
                    placeholder="Pilih File"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submit()"
                >
                  Submit
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="changeValue(false)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BCol,
  BRow, BSidebar, BFormFile, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCol,
    BRow,
    BSidebar,
    BFormFile,
    BImg,
  },
  model: {
    prop: 'addKaryawanSidebar',
  },
  props: {
    addKaryawanSidebar: {
      type: Boolean,
      required: true,
    },
    karyawan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      karyawanLocal: {
        id: null,
        nama: null,
        no_telp: null,
        posisi: null,
        gaji: null,
        foto_karyawan: null,
      },
    }
  },
  watch: {
    karyawan() {
      this.resetTaskLocal()
    },
  },
  methods: {
    resetTaskLocal() {
      this.karyawanLocal = {
        id: this.karyawan.id,
        nama: this.karyawan.nama,
        no_telp: this.karyawan.telp,
        posisi: this.karyawan.posisi,
        gaji: this.karyawan.gaji,
        foto_karyawan: this.karyawan.foto,
      }
    },
    changeValue() {
      const data = {
        id: null,
        nama: '',
        no_telp: '',
        posisi: '',
        gaji: 0,
      }
      this.$emit('karyawan-sidebar-change', data, false)
    },
    submit() {
      if (this.karyawanLocal.id > 0) {
        this.$emit('submit-data-karyawan', this.karyawanLocal)
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$emit('submit-data-karyawan', this.karyawanLocal)
          }
        })
      }
    },
  },
}
</script>
<style>
.b-sidebar-body {
  overflow: hidden !important;
}
</style>
